import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGuideAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "guideAuroresBoreales" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "comprendreAuroresBoreales"
            "photographierAuroresBoreales"
            "galerieAuroresBoreales"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["aurorePlageAbandonnee", "auroreTaiga"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesObservationduneAuroreBoreale"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUntrouCoronal"
            "auroresBorealesIndiceKp"
            "auroresBorealesUnovaleAuroral"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesArcraye"
            "auroresBorealesPhaseactive"
            "auroresBorealesAurorecoronale"
            "auroresBorealesFrance"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function GuideAuroresBoreales() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGuideAuroresBoreales")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPortraitMoyen"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Préambule">
        <p>
          Ce guide est le fruit de mes nuits passées sous les aurores. J’espère
          qu’il est complet et qu’il répondra aux questions que vous vous posez
          pour préparer votre voyage. N’hésitez pas à me contacter si vous avez
          besoin de plus de précisions.
        </p>
        <p>
          Aussi, pour mieux comprendre mes propos, je vous invite à lire cet
          article-ci : «{" "}
          <Lien codePageCible="comprendreAuroresBoreales">
            Comprendre les aurores boréales
          </Lien>{" "}
          ».
        </p>
      </Section>
      <Section titre="Où ?">
        <h3>Les ovales auroraux</h3>
        <p>
          Vues de l’espace, les aurores boréales prennent la forme de{" "}
          <strong>2 ovales</strong> centrés autour des pôles magnétiques
          terrestres. Ils sont larges de 500 à 1000 kilomètres environ. Lors
          d’une activité moyenne (la plus fréquente), les ovales se forment
          autour d’une latitude comprise entre <strong>60° et 70° nord</strong>{" "}
          environ.
        </p>
        <p>
          Si l’activité plus forte, leurs diamètres augmentent et ils descendent
          plus au sud. Par conséquent :
        </p>
        <ul>
          <li>
            Lors d’une forte activité, un observateur qui se trouve trop au nord
            verra l’aurore sur l’horizon sud.
          </li>
          <li>
            Lors d’une activité normale, un observateur situé trop au sud verra
            l’aurore sur l’horizon nord.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "auroresBorealesUnovaleAuroral")}
          langue={page.langue}
        />
        <h3>Où voyager ?</h3>
        <p>
          Toutes les <strong>régions situées sous le cercle auroral</strong>{" "}
          lors d’une activité moyenne sont idéales (c’est-à-dire à la latitude
          60° - 70° Nord environ) :
        </p>
        <ul>
          <li>Nord Norvège (région de Tromsø).</li>
          <li>Laponie.</li>
          <li>Islande.</li>
          <li>Sibérie.</li>
          <li>Canada, Alaska.</li>
        </ul>
        <h3>Norvège, Laponie ou Islande ?</h3>
        <p>Tout dépend de l’objectif de votre voyage : </p>
        <ul>
          <li>
            Le nord de la Norvège et la Laponie (Suédoise, Finlandaise) sont les
            régions les plus propices pour observer des aurores d’intensité
            normale. Dans la région de <strong>Tromsø</strong>, qui se trouve 5°
            plus au nord que l’Islande, vous y observerez une aurore d’activité
            normale au zénith. Ce qui est déjà très spectaculaire. Le climat y
            est aussi très doux (pour la latitude). Tant que l’on reste près des
            côtes, il est fréquent d’y trouver des températures supérieures à
            0°. Attention en Laponie, les températures peuvent allégrement y
            descendre sous les <strong>-30°</strong> : sortez bien couverts… (v.
            « Équipement personnel » plus loin dans cette page).
          </li>
          <li>
            L’Islande se trouve plus au sud que la Norvège : une aurore
            d’activité normale ne sera pas observée au zénith, là où elle est
            spectaculaire. Elle sera un peu plus basse sur l’horizon nord. Par
            contre, en raison du grand passé (et présent) volcanique de l’île,
            les paysages sont à couper le souffle. Attention toutefois au climat
            : il y est plus rude qu’en Norvège (vent).
          </li>
        </ul>
        <p>
          Un dernier élément à prendre en compte : <strong>l’Islande</strong>{" "}
          est devenue très, <strong>très fréquentée </strong>ces dernières
          années (le pays est de plus en plus soumis à une « surcharge »
          touristique, les autorités sont en pleine réflexion sur le sujet).
          Privilégiez des coins reculés et le nord du pays pour être
          tranquilles.
        </p>
        <Image
          image={obtenirImage(images, "auroreTaiga")}
          langue={page.langue}
        />
        <h3>Et en France ?</h3>
        <p>
          Lorsque le Soleil est au maximum de son cycle, il n’est pas rare que
          des aurores boréales se produisent en France. Pour observer le
          phènomène sous nos latitudes, il faut que l’éruption solaire qui nous
          atteint soit très forte. Cependant, à l’œil nu, ne vous attendez pas à
          vous enthousiasmer devant un festival de couleurs :{" "}
          <b>celles-ci seront probablement révélées par votre appareil photo</b>
          . Vous observerez plutôt un halo ou quelques piliers de lumière sur
          l’horizon Nord. Sauf pendant l’extraordinaire nuit du 10 au 11 mai
          2024 : les couleurs étaient parfaitement visibles, moins saturées que
          sur un appareil photo certes, mais visibles.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesFrance")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Quand ?">
        <h3>Période de l’année</h3>
        <p>
          Je vais ne traiter que de la Scandinavie puisque c’est la région que
          je connais le mieux. Là-bas, la saison des aurores boréales débute de
          fin septembre pour se terminer fin mars.
        </p>
        <ul>
          <li>
            Octobre est un mois propice pour y aller : le nombre de nuits
            claires (sans nuages) et y est le plus important de l’année. Par
            contre, vous ne bénéficierez pas encore des décors enneigés qui font
            le charme de la région.
          </li>
          <li>
            Évitez le mois de décembre : c’est la nuit polaire (mais il ne fait
            pas complètement noir tout de même).
          </li>
          <li>
            Pour un photographe, le mois de janvier est intéressant : les
            journées s’allongent peu à peu, le Soleil remonte sous l’horizon et
            le longe pendant des heures. Les lumières sont alors incroyables.
            Mais les journées restent très courtes.
          </li>
          <li>
            Les mois de février-mars sont un bon compromis entre lumières,
            ensoleillement, aurores, conditions climatiques et décors hivernaux.
          </li>
          <li>
            Les deux mois des équinoxes (septembre et mars) sont les plus
            favorables pour voir des aurores puissantes en raison de nombreuses
            failles ouvertes dans la magnétosphère terrestre (phénomène de{" "}
            <Lien urlExterne="http://spaceweather.com/archive.php?view=1&day=11&month=03&year=2018">
              Russel – McPherron
            </Lien>
            ).
          </li>
        </ul>
        <h3>La Lune</h3>
        <p>
          Privilégiez les nuits <strong>sans Lune</strong> pour mieux profiter
          des aurores. Un léger croissant peut toutefois être utile pour les
          photographes : il permettra de dévoiler les paysages sur vos clichés.
          Mes nuits favorites sont celles autour de la semaine de la nouvelle
          Lune.
        </p>
        <h3>
          Le maximum du cycle solaire est passé. Peut-on espérer voir des
          aurores boréales cet hiver ?
        </h3>
        <p>
          Oui ! Le cycle solaire est seulement lié au nombre de taches qui
          apparaissent à la surface du Soleil, qui atteint son maximum tous les
          11 ans environ.
        </p>
        <p>
          Cependant, contrairement à la croyance, les taches ne sont pas les
          seules manifestations solaires à être à l’origine des aurores
          boréales. Il y a aussi les <strong>trous coronaux</strong> (v.
          l’article «{" "}
          <Lien codePageCible="comprendreAuroresBoreales">
            Comprendre les aurores boréales
          </Lien>{" "}
          »). La formation de ces trous est indépendante du cycle solaire et ils
          apparaissent très régulièrement à la surface du Soleil.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesUntrouCoronal")}
          langue={page.langue}
        />
        <h3>À quel moment de la journée ?</h3>
        <p>
          Il est <strong>inutile</strong> de choisir une période de nuit polaire
          pour avoir plus de chances de voir une aurore boréale. L’explication
          est que la Terre tourne autour de son pôle nord géographique, alors
          que l’ovale auroral est centré autour du pôle nord magnétique (les 2
          pôles sont séparés de 8° environ). En conséquence, toutes les régions
          d’observation favorables ne se trouvent pas toujours sous l’ovale
          auroral.
        </p>
        <p>
          L’animation suivante nous aide à comprendre le phénomène (source :
          K.Akasofu -{" "}
          <Lien urlExterne="http://asahi-classroom.gi.alaska.edu/index.htm">
            Asahi aurora classroom
          </Lien>
          ) :
        </p>
        <div className="conteneur-video">
          <iframe
            src="https://player.vimeo.com/video/474171164"
            frameBorder="0"
            height="80%"
            width="300px"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <p>
          On constate que le point rose situé en Alaska pénètre dans l’ovale
          auroral vers 20h, pour y sortir vers 4h du matin. Les horaires seront
          les mêmes quel que soit le pays où vous vous trouvez : l’ovale auroral
          reste statique, il ne tourne pas autour du pôle nord magnétique. Soyez
          donc prêts à partir de 20h le soir : c’est l’horaire d’entrée dans
          l’ovale auroral.
        </p>
        <p>
          Aussi, n’allez surtout pas au lit avant minuit : vous serez alors en
          plein cœur de la région la plus active de l’ovale. Même si les aurores
          peuvent arriver assez tôt en soirée, forcez-vous à bien manger pour
          tenir toute la nuit en cas de spectacle intense.
        </p>
      </Section>
      <Section titre="Équipement personnel">
        <p>
          Observer les aurores est une activité statique : on ne bouge pas
          beaucoup pendant plusieurs heures. Vous ne profiterez donc pas du
          spectacle si le froid vous gagne trop vite.
        </p>
        <h3>Pour le corps</h3>
        <p>
          Un bon équipement de <strong>ski</strong> suffit. En cas de froid
          intense, vous multiplierez les couches : sous-vêtements de ski (vous
          en trouvez pour 30€ chez Décathlon), polaires diverses, caleçon de ski
          chaud.
        </p>
        <h3>Pour les extrémités</h3>
        <p>
          Si vous le pouvez, investissez pour couvrir les extrémités de votre
          corps. C’est en effet par là que se produisent les plus grandes
          déperditions de chaleur.
        </p>
        <ul>
          <li>
            <strong>Tête</strong> : investissez dans un gros bonnet ou une
            chapka.
          </li>
          <li>
            <strong>Mains</strong> : protégées par une paire de moufles chaudes,
            ou des gants de ski couverts de sur-gants (10 € chez Décathlon) dans
            lesquels vous glisserez une chaufferette. Prenez également des
            sous-gants : ils vous protégeront du froid quand vous aurez enlevé
            vos gants pour manipuler votre appareil photo.
          </li>
          <li>
            <strong>Pieds</strong> : c’est par là que vous sentirez le froid
            vous <strong>envahir</strong>. Évitez donc vraiment les chaussures
            de randonnées d’été : elles ne sont pas suffisamment chaudes et
            résistantes à l’humidité très froide de la neige. Investissez plutôt
            dans une bonne paire d’après-ski, ou mieux, dans une paire de Sorel
            Caribou.
          </li>
        </ul>
        <h3>Autres accessoires</h3>
        <p>
          Ayez toujours avec vous <strong>une paire de crampons</strong>{" "}
          (vendues une dizaine d’euros en supermarchés) : elles pourront vous
          éviter des glissades et de bien mauvaises chutes si de la glace est
          présente au sol (ou si elle est cachée sous la neige).
        </p>
        <p>
          Prévoyez quelques <strong>chaufferettes</strong> (mains et pieds
          surtout). Évitez les chaufferettes rechargeables dans l’eau
          bouillante. Vous oublierez de les recharger et elles sont peu
          confortables (elles sont très dures). Les modèles jetables de chez
          décathlon à 10 € la boîte feront parfaitement l’affaire.
        </p>
        <p>
          Enfin, n’oubliez pas l’indispensable lampe frontale. Choisissez-en une
          avec <strong>un éclairage rouge</strong> pour conserver votre vision
          nocturne et ne pas éblouir vos camarades d’observation.
        </p>
      </Section>
      <Section titre="La météo">
        <p>
          L’aurore boréale est un phénomène de haute altitude, qui se produit{" "}
          <strong>bien au-delà des nuages</strong> (à partir de 70 kilomètres
          d’altitude environ). Par conséquent, les prévisions météo sont à
          surveiller tout autant que les prévisions de tempête solaire.
        </p>
        <p>
          Mais ne désespérez pas si une soirée commence sous les nuages alors
          que les prévisions de tempête solaire sont bonnes : le temps change
          vite sur la côte norvégienne. Surveillez donc les animations
          satellites pour voir si des trouées nuageuses se dirigent vers vous
          (v. les liens en fin d’article).
        </p>
      </Section>
      <Section titre="Déplacements et conditions de circulation">
        <p>Avant tout déplacement, surveillez attentivement :</p>
        <ul>
          <li>
            <strong>La météo</strong> : anticipez les conditions compliquées :
            tempête de neige, vent violent…
          </li>
          <li>
            <strong>Les conditions de circulation</strong> (v. liens utiles en
            bas de page). N’oubliez pas que vous vous trouvez en arctique.
          </li>
        </ul>
        <p>C’est surtout vrai en Islande :</p>
        <ul>
          <li>
            Les routes indiquées par votre GPS (Google Maps et consœurs…)
            peuvent être des chemins défoncés sur des dizaines de kilomètres,
            sans couverture téléphonique. En cas de problème météo vous pouvez
            vite vous trouver en difficulté. Ayez un regard critique sur la
            route proposée par votre GPS et privilégiez les axes principaux.
          </li>
          <li>
            En plus des tempêtes de neige, faites très attention aux tempêtes de
            sable dans le sud du pays.
          </li>
        </ul>
        <p>
          Lors de votre arrivée sur place, prévoyez également d’acheter une
          pelle à neige (une quinzaine d’euros) dans un centre auto, elle peut
          vous rendre de très grands services et vous sortir de situation
          difficiles. Pensez aussi à une corde de remorquage.
        </p>
        <p>
          Enfin, ne prenez jamais de risque et évitez les bas-côtés des routes
          enneigées, très mous : vous pouvez vous y « tanker ». Pensez-y si vous
          devez faire un demi-tour.
        </p>
      </Section>
      <Section titre="Repérage, où regarder ?">
        <p>
          Vous devrez vous éloigner des villes et de leur pollution lumineuse.
        </p>
        <p>
          Les aurores apparaîtront sur{" "}
          <strong>l’horizon Nord, d’Ouest en Est</strong>, ou au zénith.
          Assurez-vous donc qu’au moins uns de ces horizons soient bien éloignés
          de toute ville importante.
        </p>
        <p>
          Si vous comptez prendre des photos de qualité, soyez plus exigeant sur
          le repérage : v. l’article{" "}
          <Lien codePageCible="photographierAuroresBoreales">
            Photographier les aurores boréales
          </Lien>
          .
        </p>
        <Image
          image={obtenirImage(images, "aurorePlageAbandonnee")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Pendant la nuit…">
        <p>
          Restez aux aguets d’environ{" "}
          <strong>19 heures jusqu’à au moins minuit</strong> (une importante
          activité peut se produire à cette heure : vous serez au cœur de
          l’ovale auroral situé autour du pôle, v. plus haut).
        </p>
        <p>
          Plusieurs ondes de choc devraient se produire : l’aurore va perdre de
          l’activité, puis va en regagner. Dites-vous que les aurores sont
          capricieuses : il suffit que l’on souhaite partir parce qu’il n’y a
          plus d’activité pour que l’aurore se réveille… Aussi, en cas de grosse
          tempête solaire, il peut arriver que l’aurore soit visible alors que
          le Soleil vient juste de se coucher (il peut faire encore jour),
          jusqu’à très tard dans la nuit. Il m’est ainsi arrivé d’observer une
          aurore en Islande vers 17h30 qui s’est achevée vers 5h du matin !
        </p>
        <p>
          Si le froid vous gagne, profitez d’une pause de l’activité aurorale
          pour vous réchauffer : buvez une boisson chaude, bougez, mettez vos
          mains sous vos aisselles.
        </p>
        <p>
          Prenez également régulièrement des nouvelles des autres membres de
          votre groupe.
        </p>
      </Section>
      <Section titre="Prévision des aurores boréales">
        <h3>Principe</h3>
        <p>
          Tout commence par l’observation du Soleil. Il est scruté en permanence
          par des satellites comme{" "}
          <Lien urlExterne="https://fr.wikipedia.org/wiki/Solar_Dynamics_Observatory">
            SDO
          </Lien>{" "}
          (Solar Dynamics Observatory) et{" "}
          <Lien urlExterne="https://fr.wikipedia.org/wiki/STEREO">STEREO</Lien>{" "}
          (Solar TErrestrial RElations Observatory) :
        </p>
        <ul>
          <li>
            SDO est chargé de surveiller le Soleil. Un de ses missions est de
            prendre des photos du Soleil dans différentes longueurs d’ondes pour
            étudier les différentes couches de son atmosphère.
          </li>
          <li>
            STEREO A et B sont deux satellites qui se trouvent en amont et en
            aval de l’orbite de la Terre autour du Soleil (aux{" "}
            <Lien urlExterne="https://fr.wikipedia.org/wiki/Point_de_Lagrange">
              points de Lagrange
            </Lien>{" "}
            L4 et L5). Leur rôle est de scruter les faces du Soleil que nous ne
            voyons pas, et de réaliser des modèles 3D des éruptions solaires.
          </li>
        </ul>
        <p>
          Si une éjection de masse coronale (v. l’article «{" "}
          <Lien codePageCible="comprendreAuroresBoreales">
            comprendre les aurores boréales
          </Lien>{" "}
          ») est détectée, nous le saurons 8 minutes plus tard environ, le temps
          que la lumière arrive jusqu’aux satellites. Il reste à savoir si la
          trajectoire du nuage de plasma solaire va croiser celle de la Terre.
          Sa direction et sa taille peuvent déjà être estimées à partir des
          mesures des satellites STEREO.
        </p>
        <p>
          Une première prévision à 3 jours est alors réalisée : le nuage de
          plasma voyage entre 400 et 750 km/s environ, il mettra 2 à 3 jours
          pour croiser la Terre.
        </p>
        <p>
          2 à 3 jours plus tard, le nuage « transperce » le satellite{" "}
          <Lien urlExterne="https://fr.wikipedia.org/wiki/Advanced_Composition_Explorer">
            ACE
          </Lien>{" "}
          (Advanced Composition Explorer). Ce satellite, placé 1.5 millions de
          km devant la Terre, sur la trajectoire des nuages de plasma, est une
          véritable sentinelle. Les nuages qui le croisent seront analysés en
          même temps : densité, vitesse, champ magnétique, etc. À partir de ces
          mesures, une prévision plus précise pour les 1 à 2 prochaines heures
          sera réalisée.
        </p>
        <div className="conteneur-video">
          <iframe
            src="https://player.vimeo.com/video/474174369"
            frameBorder="0"
            height="100%"
            width="300px"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <p>
          Ci-dessus, une éruption solaire modélisée par les satellites STEREO.
          La première vue est une vue « d’en haut » du système solaire jusqu’à
          la Terre. La deuxième vue est la même vue, de profil, côté Terre. Les
          grandes spirales vertes correspondent aux lignes de champs magnétiques
          interplanétaires émises par Soleil (
          <Lien urlExterne="https://fr.wikipedia.org/wiki/Spirale_de_Parker">
            la spirale de Parker
          </Lien>
          ).
        </p>
        <h3>L’indice Kp</h3>
        <p>
          L’indice Kp est un <strong>indice mondial</strong> de déformation du
          champ magnétique terrestre. Il peut prendre des valeurs comprises
          entre 1 et 9. Cet indice et a été introduit par le géophysicien
          Allemand Julius Bartel en 1938 :
        </p>
        <ul>
          <li>
            « K » : pour « Kennziffer » en allemand (= « numéro de code »).
          </li>
          <li>
            « p » : pour « planetary ». Le « Kp » est donc une moyenne mondiale
            calculée à partir de différentes stations d’observation terrestres.
            On parle de « K-Index » pour une mesure locale.
          </li>
        </ul>
        <p>
          Si une aurore boréale se produit, alors très probablement le champ
          magnétique terrestre est fortement comprimé par la tempête solaire
          qu’il subit. C’est pourquoi cet indice est utilisé pour prévoir la
          puissance des aurores boréales.
        </p>
        <p>
          <strong>Conséquences :</strong>
        </p>
        <ul>
          <li>
            Plus l’indice Kp est élevé, plus l’aurore devrait être intense (mais
            ce n’est pas toujours vrai, v. « Les limites des prévisions »
            ci-dessous).
          </li>
          <li>
            Mais l’ovale auroral va descendre : il ne faudra pas se trouver trop
            au nord pour profiter de l’aurore.
          </li>
          <li>
            On parle de tempête géo magnétique quand le Kp est supérieur à 5.
          </li>
        </ul>
        <p>
          En Norvège du nord, un Kp compris entre 2 et 3 suffit pour voir une
          aurore boréale au zénith. En France, pour voir une aurore rouge (vers
          le nord), un Kp supérieur à 8 sera nécessaire.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesIndiceKp")}
          langue={page.langue}
        />
        <h3>D’autres mesures</h3>
        <p>
          D’autres mesures peuvent également vous être proposées sur vos
          applications smartphone ou par des sites spécialisés, parfois
          synthétisées en un unique indicateur baptisé « puissance du vent
          solaire » :
        </p>
        <ul>
          <li>
            L’orientation magnétique du vent solaire (Bz) : c’est la mesure la
            plus importante. Elle doit être orientée au sud (Bz négatif) pour
            que l’aurore se produise. Une connexion avec la magnétosphère
            terrestre va se produire grâce à l’orientation nord du champ
            magnétique de la magnétopause (zone « tampon » entre le champ
            magnétique interplanétaire du Soleil et la magnétosphère de la
            Terre). Suite à un cheminement complexe, des particules de vent
            solaire vont s’engouffrer dans la magnétosphère terrestre et
            provoquer des aurores boréales (plus d’explications{" "}
            <Lien urlExterne="https://www-spof.gsfc.nasa.gov/Education/wmpause.html">
              ici
            </Lien>
            ).
          </li>
          <li>
            La vitesse du vent solaire : plus elle est importante, plus l’impact
            sur la magnétosphère sera violent, et plus l’aurore sera belle
            (exprimée en km/sec). Des valeurs supérieures à 300 km/s sont
            nécessaires pour observer des aurores boréales.
          </li>
          <li>
            La densité du vent solaire : plus le nuage est riche et dense en
            particules, plus la vitesse du vent solaire est élevée, plus
            l’impact sur la magnétosphère sera fort et plus l’aurore sera belle.
            Cette mesure est exprimée en protons/cm³ et doit être supérieure à 1
            pour observer des aurores boréales.
          </li>
          <li>
            <Lien urlExterne="http://services.swpc.noaa.gov/images/ace-mag-swepam-24-hour.gif">
              Ces mesures sont récupérées par le satellite ACE
            </Lien>{" "}
            (v. § juste plus haut : « Principe ») et elles entrent en compte
            dans le calcul de prévision de l’indice Kp. Conclusion : tenez-vous
            en aux prévisions de l’indice Kp :-)
          </li>
        </ul>
        <h3>Les limites des prévisions</h3>
        <p>
          Les aurores boréales sont capricieuses et leur prévisibilité n’est pas
          une science exacte :
        </p>
        <ul>
          <li>
            <strong>Les aurores brillantes</strong> se forment uniquement grâce
            à la reconnexion magnétique qui se produit dans la queue de la
            magnétosphère (v. l’article «{" "}
            <Lien codePageCible="comprendreAuroresBoreales">
              comprendre les aurores boréales
            </Lien>{" "}
            »). Ce phénomène étant encore mal compris par les scientifiques, la
            prévision des aurores n’est donc pas 100 % fiable.
          </li>
          <li>
            <strong>Les aurores « surprises »</strong> : la reconnexion
            magnétique n’est pas forcément liée aux tempêtes solaires. Celles-ci
            créent juste des conditions très favorables pour qu’elle se
            produise. De plus, la reconnexion magnétique peut aussi se produire
            sans tempête solaire : il se produit alors une aurore boréale «
            surprise », qui n’était pas prévue.
          </li>
          <li>
            Le <strong>temps d’actualisation</strong> de vos applications
            smartphones. Les aurores boréales sont des phénomènes brefs. Les
            phases intenses durent une dizaine de minutes environ (ou bien plus
            en cas de forte tempête), ce qui n’est souvent pas un délai
            suffisant pour que nos applications soient averties en temps et en
            heures. En effet, il faut toujours compter quelques minutes le temps
            que les résultats des calculs des centres de mesures soient publiés,
            diffusés, puis poussés sur nos appareils.
          </li>
        </ul>
      </Section>
      <Section titre="Les phases et les formes des aurores boréales">
        <h3>Note au lecteur</h3>
        <p>
          Ce que j’écris ici est le fruit de mes soirées passées sous les
          aurores boréales, mais aussi celui des observations de chercheurs
          locaux avec lesquels je me suis entretenu. Cependant, beaucoup de
          soirées m’ont réservé des surprises… N’allez donc pas au lit trop vite
          !
        </p>
        <h3>Hauteur de l’aurore sur l’horizon</h3>
        <p>
          De votre position dépendra la forme de l’aurore que vous observerez :
        </p>
        <Image
          image={obtenirImage(
            images,
            "auroresBorealesObservationduneAuroreBoreale"
          )}
          langue={page.langue}
        />
        <h3>Phase 1 - l’arc diffus</h3>
        <p>
          L’activité aurorale est normale ou basse et l’aurore reste assez
          statique dans le ciel. Elle est peu lumineuse, de couleur blanche.
          L’aurore pourrait se confondre avec un nuage. Si vous avez des doutes,
          lancez une pose photographique : si le nuage apparaît en vert sur la
          photo, alors c’est une aurore qui ne demande qu’à s’activer. Patientez
          donc, surtout si les prévisions de Kp sont bonnes pour la soirée.
        </p>
        <h3>Phase 2 - l’arc rayé</h3>
        <p>
          Un nuage de particules solaires commence à agiter l’ionosphère.
          L’aurore commence à doucement danser dans le ciel, un peu comme un
          rideau battant au vent à côté d’une fenêtre ouverte. Les stries de
          l’aurore correspondent à des lignes de champs magnétiques terrestres.
          Cette phase est appelée la phase de déclenchement.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesArcraye")}
          langue={page.langue}
        />
        <h3>Phase 3 – la phase d’expansion</h3>
        <p>
          L’activité est à son maximum. Si la tempête solaire est intense,
          l’aurore peut occuper tout le ciel et bouger à des vitesses
          incroyables. Il peut y avoir plusieurs phases d’expansion au cours
          d’une soirée. Encore une fois, n’allez pas au lit tout de suite, même
          si l’aurore boréale semble s’éteindre.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesPhaseactive")}
          langue={page.langue}
        />
        <h3>Phase 5 - L’aurore coronale</h3>
        <p>
          L’aurore coronale marque souvent la fin de la phase la plus active
          d’une nuit pendant laquelle une intense tempête solaire s’est abattue
          sur Terre. C’est la phase la plus spectaculaire de l’aurore, celle qui
          reste longtemps dans les mémoires tellement sa puissance visuelle est
          intense.
        </p>
        <p>
          L’aurore est très lumineuse. Toutes ses stries semblent venir d’un
          même point, elle tournoie autour de nos têtes : elle a la forme d’une
          couronne, d’où son nom. Elle se produit au maximum de l’activité de la
          nuit, vers 0h-1h du matin, au milieu de l’ovale auroral. Il faut que
          le niveau d’activité soit fort pour qu’elle se produise. Elle est donc
          assez rare, et surtout elle ne dure pas très longtemps (5min).
          Profitez donc du spectacle.
        </p>
        <p>
          Si votre appareil photo n’est pas prêt, inutile de vous précipiter
          dessus : le phénomène sera bref.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesAurorecoronale")}
          langue={page.langue}
        />
        <h3>Phase 6 : l’aurore à pulsations (ou pulsatile)</h3>
        <p>
          L’aurore boréale à pulsations marque souvent la fin de la nuit
          d’observations. Ce type d’aurore fait partie de la phase dite de «
          recouvrement ». Elles font suite à une très forte tempête solaire,
          elles sont donc rares. Je les ai à chaque fois vues vers 2 ou 3h du
          matin.
        </p>
        <p>
          Il m’est arrivé une fois de voir une aurore regagner en intensité
          après une phase pulsatile. La vitesse et l’intensité des pulsations
          varient d’une soirée à l’autre : des fois très lentes, des fois
          extrêmement rapides (plusieurs clignotements par seconde).
        </p>
        <p>
          Voici un timelapse d’aurore à pulsations réalisé en janvier 2013 dans
          les îles Lofoten en Norvège :
        </p>
        <div className="conteneur-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1ma6500fsIw"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Section>
      <Section titre="En résumé…">
        <p>Gardez les quelques conseils suivants en tête :</p>
        <ul>
          <li>Surveillez les prévisions de Kp.</li>
          <li>
            Prévisions de Kp à 3 jours : elles sont utiles pour repérer de
            futurs spots photo et anticiper d’éventuels déplacements dans le
            pays dans lequel vous êtes.
          </li>
          <li>
            Prévisions pour la soirée : si elles sont bonnes, prenez un repas
            copieux et prévoyez de rester au moins jusqu’à minuit dehors. Si
            elles ne sont pas bonnes et que le ciel est dégagé : jetez
            régulièrement un œil dehors, une aurore boréale « surprise » peut se
            produire à tout moment (v. « les limites des prévisions »).
          </li>
          <li>
            Surveillez la météo : des trouées de ciel clair seront nécessaires.
            Vérifiez les prévisions de températures et le vent pour vous équiper
            en conséquence.
          </li>
          <li>
            Lors d’une soirée intense, n’allez jamais au lit avant minuit. Même
            si l’aurore semble s’être éteinte, elle peut redevenir très
            puissante à tout moment.
          </li>
        </ul>
      </Section>
      <Section titre="Liens utiles">
        <h3>Météo et couverture nuageuse</h3>
        <ul>
          <li>
            <Lien urlExterne="http://www.yr.no">
              Prévisions météo en Norvège et Suède.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://en.vedur.is/">
              Prévisions météo en Islande.
            </Lien>
          </li>
          <li>
            <Lien codePageCible="previsionsCouvertureNuageuse">
              Prévisions couverture nuageuse.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://www.yr.no/satellitt/europa_animasjon.html">
              Animation satellite en Europe du Nord.
            </Lien>{" "}
            (Sat24 ne fonctionne pas pour le nord de la Scandinavie).
          </li>
        </ul>
        <h3>Conditions de circulation</h3>
        <ul>
          <li>
            <Lien urlExterne="https://www.trafikverket.se/en/startpage/operations/Operations-road/Traffic-information/">
              En Suède.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.road.is/">En Islande.</Lien>
          </li>
        </ul>
        <h3>Météo solaire</h3>
        <ul>
          <li>
            <Lien urlExterne="http://www.swpc.noaa.gov/products/3-day-forecast">
              Prévisions de Kp à 3 jours.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.swpc.noaa.gov/products/planetary-k-index">
              Prévisions de Kp pour les prochaines heures.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://twitter.com/aurora_alerts">
              Aurora alerts sur Twitter.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.spaceweather.com/">
              Space Weather.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://www.facebook.com/groups/722944715998047/">
              Aurores Boréales en France : groupe de discussion Facebook autour
              de l’observation d’aurores boréales en France.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://www.spaceweatherlive.com/fr">
              Space Weather Live (en français).
            </Lien>
          </li>
        </ul>
      </Section>
      <Section titre="Applications utiles pour smartphones">
        <p>Quelques applications utiles pour smartphones :</p>
        <ul>
          <li>
            Ios :{" "}
            <Lien urlExterne="https://apps.apple.com/fr/app/solar-monitor-3/id1317284385">
              Solar Monitor
            </Lien>
            . Météo :{" "}
            <Lien urlExterne="https://itunes.apple.com/us/app/windy-wind-and-waves-forecast/id1161387262?mt=8">
              Windy
            </Lien>{" "}
            (couverture nuageuse),{" "}
            <Lien urlExterne="https://itunes.apple.com/fr/app/yr/id490989206?mt=8">
              yr.no
            </Lien>
            …
          </li>
          <li>
            Android :{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=com.jrustonapps.myauroraforecast">
              My Aurora Forecast
            </Lien>
            ,{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=com.spaceweatherlive.app">
              Space weather live
            </Lien>
            . Météo :{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=com.windyty.android&hl=fr">
              Windy
            </Lien>{" "}
            (couverture nuageuse),{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=no.nrk.yr&hl=fr">
              yr.no
            </Lien>
            …
          </li>
        </ul>
      </Section>
    </LayoutTutorielFR>
  )
}
